import React, { useContext, useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  Fab,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  CircularProgress,
  Stack,
  TextField,
  TablePagination,
} from "@mui/material";
import { del, get, post, put } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import dayjs from "dayjs";
import { PickerViewRoot } from "@mui/x-date-pickers/internals/components/PickerViewRoot";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { toast } from "react-toastify";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import SectionSubHeader from "../../SectionSubHeader";
import { LoadingButton } from "@mui/lab";

const styles = {
  icon: {
    padding: "10px",
    fontSize: "12px",
    fontWeight: 500,
  },
  card: {
    padding: 15,
    marginBottom: 20,
    width: "100%",
  },
  textfield: {
    width: "100%",
    label: { fontSize: 12 },
  },
  Button: {
    background: "#1b3779",
    color: "#fff",
    ":hover": { background: "#1b3779" },
  },
};

const showInfo = (data) => {
  let result = [];

  for (let dep of data.dependencies) {
    if (["class"].includes(dep)) {
      let newItem = `[${data.class?.className}]-Class`;
      result.push(newItem);
    } else if (["classOld"].includes(dep)) {
      let newItem = `[${data.class?.className}]-Class-Old`;
      result.push(newItem);
    } else if (["classNew"].includes(dep)) {
      let newItem = `[${data.class?.className}]-Class-New`;
      result.push(newItem);
    } else if (dep === "hostel") {
      let newItem = `[${data.hostel?.name}]-Hostel`;
      result.push(newItem);
    } else if (dep == "transport") {
      let newItem = `[${data.route.transportRouteTitle}]--[${data.stop.stopName}]--[${data.pickType}]`;
      result.push(newItem);
    } else if (dep == "pickType") {
      let newItem = `[${data.pickType}]-Pick_Type`;
      result.push(newItem);
    }
  }

  return result.join(" | ");
};

function FeeOverviewNew() {
  const { selectedSetting } = useContext(SettingContext);
  const [academicYears, setAcademicYears] = useState([]);
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [formData, setFormData] = useState([]);
  const [receipts, setReceipts] = useState([]);
  const [collected, setCollected] = useState([]);
  const [fromDate, setFromDate] = useState(dayjs().subtract(1, "day"));
  const [toDate, setToDate] = useState(null);
  const [totalCount, setTotalCount] = useState();
  const [masterFeeOverview, setMasterFeeOverview] = useState([]);
  const [filterFeeOverview, setFilterFeeOverview] = useState([]);
  const [differentFeeOverView, setDifferentFeeOverView] = useState({
    modes: {},
    total: 0,
    balance: 0,
  });
  const [staffCopy, setStaffCopy] = useState([]);
  const [category, setCategory] = useState("admissionNumber");
  const [searchValue, setSearchValue] = useState("");
  const [finder, setFinder] = useState(false);
  const [download, setDownload] = useState(false);

  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = React.useState(0);
  const [downloadingStaffCopy, setDownloadingStaffCopy] = useState("");

  const [feeMaps, setFeeMaps] = useState([]);

  const [sort, setSort] = useState({
    receiptNumber: "asc",
    class: "asc",
  });

  const sortByReceiptNumber = (e) => {
    if (sort.receiptNumber == "asc") {
      let sortedList = [
        ...masterFeeOverview.sort((a, b) => b.receiptNumber - a.receiptNumber),
      ];

      setFilterFeeOverview(sortedList);
      setSort({ ...sort, receiptNumber: "des" });
    } else {
      let sortedList = [
        ...masterFeeOverview.sort((a, b) => a.receiptNumber - b.receiptNumber),
      ];
      setFilterFeeOverview(sortedList);
      setSort({ ...sort, receiptNumber: "asc" });
    }
  };

  const sortByClass = (e) => {
    if (sort.class == "asc") {
      let sortedList = [
        ...masterFeeOverview.sort((a, b) =>
          b.payeeDetails?.className.localeCompare(a.payeeDetails?.className)
        ),
      ];

      setFilterFeeOverview(sortedList);
      setSort({ ...sort, class: "des" });
    } else {
      let sortedList = [
        ...masterFeeOverview.sort((a, b) =>
          a.payeeDetails?.className?.localeCompare(b.payeeDetails?.className)
        ),
      ];
      setFilterFeeOverview(sortedList);
      setSort({ ...sort, class: "asc" });
    }
  };

  // filter pagination==========
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    const getAcademicYears = async () => {
      try {
        const res = await get(urls.administrator.academicYearGetAll, {
          headers: { roleFunction: "feeOverview" },
        });
        setFormData((prev) => ({
          ...prev,
          academicYear: res.data.data[0]._id,
        }));
        setAcademicYears(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAcademicYears();
  }, []);

  useEffect(() => {
    const getAllReceipts = async () => {
      try {
        const res = await get(urls.receiptTitle.list, {
          headers: { roleFunction: "feeOverview" },
          params: {
            search: {
              active: true,
            },
          },
        });
        // setFormData((prev) => ({
        //   ...prev,
        //   receiptTitleId: res.data.data.length ? res.data.data[0]._id : "",
        // }));
        setReceipts(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAllReceipts();
  }, [selectedSetting._id]);

  const getAllFeeMaps = async () => {
    try {
      const { data } = await get(
        urls.feeMap.list + "/" + formData.receiptTitleId,
        {
          headers: { roleFunction: "feeOverview" },
          params: { search: { active: true } },
        }
      );

      setFeeMaps(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (formData.receiptTitleId) {
      getAllFeeMaps();
    }
  }, [formData.receiptTitleId, selectedSetting._id]);

  const getClasses = async () => {
    try {
      const res = await get(urls.class.getAllClass, {
        headers: { roleFunction: "feeOverview" },
      });
      setClasses(res.data.data);
      // if (formData.feeMapId) {
      //   let feeMap = feeMaps.filter((f) => f._id == formData.feeMapId)[0];
      //   if (feeMap.dependencies.includes("class")) {
      //     return setFormData((prev) => ({
      //       ...prev,
      //       class: feeMap.class._id,
      //     }));
      //   }
      // }
      // setFormData((prev) => ({
      //   ...prev,
      //   class: res.data?.data[0] ? res.data?.data[0]?._id : "",
      // }));
    } catch (error) {
      console.log(error);
    }
  };

  // get all classes
  useEffect(() => {
    getClasses();
  }, [selectedSetting._id, formData.feeMapId]);

  const getSections = async () => {
    try {
      let filter = {};
      if (!formData.class?.includes("all")) {
        filter.class = { $in: formData.class };
      }
      const { data } = await get(urls.schoolSection.getAllSection, {
        headers: { roleFunction: "feeOverview" },
        params: { search: filter },
      });
      setSections(data.data);
      // setFormData((prev) => ({
      //   ...prev,
      //   section: data.data[0] ? data.data[0]._id : "",
      // }));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (formData.class) {
      getSections();
    }
  }, [formData.class, selectedSetting._id]);

  useEffect(() => {
    const getCollected = async () => {
      try {
        const roleResponse = await get(urls.role.getAllRole, {
          headers: { roleFunction: "feeOverview" },
          params: {
            search: {
              $or: [
                { roleName: "ACCOUNTANT" },
                { roleName: "SUPER ADMIN" },
                { roleName: "ADMIN" },
              ],
            },
          },
        });
        if (!roleResponse.data.data.length) return;
        const roleId = roleResponse.data.data[0]._id;

        const roleIds = roleResponse.data.data.map((r) => r._id);

        const employeeResponse = await get(urls.employee.getAllEmployee, {
          headers: { roleFunction: "feeOverview" },
          params: {
            search: {
              role: { $in: roleIds },
            },
          },
        });
        // setFormData((prev) => ({
        //   ...prev,
        //   name: employeeResponse.data.data.length
        //     ? employeeResponse.data.data[0]._id
        //     : "",
        // }));
        setCollected(employeeResponse.data.data);
        setTotalCount(employeeResponse.data.count);
      } catch (error) {
        console.log("error", error);
      }
    };
    getCollected();
  }, []);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    console.log(name, "NAME");
    console.log(value, "values");
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleGetPrintPdf = async (id) => {
    setDownloadingStaffCopy(id);
    try {
      {
        const getPrint = await get(urls.receipt.downloadReceiptStaff + id, {
          headers: { roleFunction: "feeOverview" },
          responseType: "blob",
        });

        setDownloadingStaffCopy("");

        const uri = URL.createObjectURL(getPrint.data);
        window.open(uri, "__blank");
      }
    } catch (error) {
      console.log(error);
      setDownloadingStaffCopy("");
    }
  };
  const handleMarksExcelDownload = async () => {
    setDownload(true);
    if (
      !formData.academicYear ||
      !formData.collectedby ||
      !formData.feeMapId ||
      !formData.section ||
      !formData.class ||
      !fromDate ||
      !toDate
    ) {
      alert("Please fill required fields!");
      return setDownload(false);
    }

    try {
      {
        const getMarksListSheet = await get(urls.receipt.downloadFeeOverView, {
          headers: { roleFunction: "feeOverview" },
          params: {
            academicYearId: formData.academicYear,
            cashierIds: formData.collectedby?.includes("all")
              ? "all"
              : formData.collectedby,
            receiptTitleIds: formData.receiptTitleId?.includes("all")
              ? "all"
              : formData.receiptTitleId,
            feeMapIds: formData.feeMapId?.includes("all")
              ? "all"
              : formData.feeMapId,
            sectionIds: formData.section?.includes("all")
              ? "all"
              : formData.section,
            fromDate: fromDate,
            classIds: formData.class?.includes("all") ? "all" : formData.class,
            toDate: toDate,
          },
          responseType: "blob",
        });

        if (getMarksListSheet.status === 200) {
          const uri = URL.createObjectURL(getMarksListSheet.data);
          const link = document.createElement("a");
          link.href = uri;
          // const academicYearGet = formData.academicYear;
          // const getExcelByYearId = academicYears.find(
          //   (excel) => excel._id === academicYearGet
          // );
          // const classename = formData.class;
          // const getExcelByClassId = classes.find(
          //   (excel) => excel._id === classename
          // );
          // const sectionsname = formData.section;
          // const getExcelsectionById = sections.find(
          //   (excel) => excel._id === sectionsname
          // );
          // const receiptss = formData.receiptTitleId;
          // const getExcelreceiptById = receipts.find(
          //   (excel) => excel._id === receiptss
          // );

          // link.setAttribute(
          //   "download",
          //   `${getExcelByYearId.academicYearFrom}-${
          //     getExcelByYearId.academicYearTo
          //   } ${getExcelByClassId.className}th std ,${
          //     getExcelsectionById
          //       ? getExcelsectionById.sectionName
          //       : "All sections"
          //   }, ${getExcelreceiptById.name}.xlsx`
          // );
          document.body.appendChild(link);
          link.click();

          link.parentNode.removeChild(link);
        }
      }
    } catch (error) {
      console.log(error, "error");
      toast.error(error.message);
    }
    setDownload(false);
  };

  const handleFeeOverviewSearchSubmit = async (e) => {
    e.preventDefault();
    setFinder(true);
    if (
      !formData.academicYear ||
      !formData.collectedby ||
      !formData.feeMapId ||
      !formData.section ||
      !formData.class ||
      !fromDate ||
      !toDate
    ) {
      alert("Please fill required fields!");
      return setFinder(false);
    }
    try {
      const searchParams = {
        headers: { roleFunction: "feeOverview" },
        params: {
          academicYearId: formData.academicYear,
          cashierIds: formData.collectedby?.includes("all")
            ? "all"
            : formData.collectedby,
          receiptTitleIds: formData.receiptTitleId?.includes("all")
            ? "all"
            : formData.receiptTitleId,
          feeMapIds: formData.feeMapId?.includes("all")
            ? "all"
            : formData.feeMapId,
          sectionIds: formData.section?.includes("all")
            ? "all"
            : formData.section,
          fromDate: fromDate,
          classIds: formData.class?.includes("all") ? "all" : formData.class,
          toDate: toDate,
        },
      };
      const differentMode = await get(
        urls.receipt.getAmountCollectedWithDifferentModes,
        { ...searchParams }
      );
      // console.log(differentMode, "================");
      setDifferentFeeOverView({
        modes: differentMode.data.data.finalResult,
        total: differentMode.data.data.results[0].totalCollectedAmount,
        balance: differentMode.data.data?.balance,
      });

      const searchStudent = await get(
        urls.receipt.getAllReceipts,
        searchParams
      );

      console.log(searchStudent.data.data, "datatatatatatata");
      setMasterFeeOverview(searchStudent.data.data);
      setFilterFeeOverview(searchStudent.data.data);
      setTotalCount(searchStudent.data.count);
    } catch (error) {
      console.log(error);
      setFinder(false);
    }
    setFinder(false);
  };

  useEffect(() => {
    setDifferentFeeOverView({
      modes: {},
      total: 0,
    });
    setMasterFeeOverview([]);
    setFilterFeeOverview([]);
    setTotalCount(0);
  }, [fromDate, toDate, formData]);

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
    filterData(category, event.target.value);
  };

  const filterData = (category, search) => {
    const filteredResults = masterFeeOverview.filter((data) => {
      if (category == "receiptNumber") {
        let lengthofgivensearch = search.length;
        let receiptNumber = data.receiptNumber.substring(
          0,
          lengthofgivensearch
        );
        return search == receiptNumber;
      } else if (category === "totalAmountPaid") {
        return data.amountPaid.toString().includes(search);
      } else if (category === "paymentMode") {
        let paymentMode = data.paymentMode
          .toLowerCase()
          .includes(search.toLowerCase());
        return paymentMode;
      } else if (category === "name") {
        return data.payeeDetails.name
          .toLowerCase()
          .includes(search.toLowerCase());
      } else if (category === "admissionNumber") {
        let admissionNumber = data.payeeDetails.admissionNumber
          .toLowerCase()
          .includes(search.toLowerCase());
        return admissionNumber;
      }
      return true;
    });
    setFilterFeeOverview(filteredResults);
  };

  const sortList = (sortBy) => {
    if (sortBy) {
      if (sortBy === "receiptNumber") {
        setFilterFeeOverview(
          masterFeeOverview.sort((a, b) =>
            a.receiptNumber > b.receiptNumber
              ? 1
              : b.receiptNumber > a.receiptNumber
              ? -1
              : 0
          )
        );
      }
    }
  };

  return (
    <Box sx={{ padding: "1rem" }}>
      <div>
        <SectionSubHeader title="Fee Overview" />
        <Card style={{ margin: "20px 0", padding: 10 }}>
          <Box sx={{ flexGrow: 1 }}>
            <form onSubmit={handleFeeOverviewSearchSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={3}>
                  <FormControl fullWidth required size="small">
                    <InputLabel>Academic Year</InputLabel>
                    <Select
                      label="Academic Year"
                      id="demo-simple-select-filled"
                      name="academicYear"
                      value={formData.academicYear || ""}
                      onChange={handleOnChange}
                      size="small"
                    >
                      {academicYears &&
                        academicYears.map((row, index) => (
                          <MenuItem key={row._id} value={row._id}>
                            {row.academicYearFrom} - {row.academicYearTo}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3}>
                  <FormControl fullWidth required size="small">
                    <InputLabel>Collected By</InputLabel>
                    <Select
                      label="Collected By"
                      name="collectedby"
                      value={formData.collectedby || []}
                      onChange={handleOnChange}
                      size="small"
                      multiple
                    >
                      <MenuItem value={"all"}>All</MenuItem>
                      {collected &&
                        collected.map((row, index) => (
                          <MenuItem key={row._id} value={row._id}>
                            {row.basicInfo.empName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3}>
                  <FormControl fullWidth required size="small">
                    <InputLabel>Fee Receipt</InputLabel>
                    <Select
                      label="Fee Receipt"
                      id="demo-simple-select-filled-feereceipt"
                      name="receiptTitleId"
                      value={formData.receiptTitleId || []}
                      onChange={handleOnChange}
                      size="small"
                      multiple
                    >
                      <MenuItem value={"all"}>All</MenuItem>

                      {receipts &&
                        receipts.map((row, index) => (
                          <MenuItem key={row._id} value={row._id}>
                            {row.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={3}>
                  <FormControl fullWidth required size="small">
                    <InputLabel id="demo-simple-select-helper-label">
                      Class
                    </InputLabel>
                    <Select
                      disabled={
                        formData.feeMapId &&
                        feeMaps
                          .filter((f) => f._id == formData.feeMapId)[0]
                          ?.dependencies.includes("class")
                      }
                      label="Class"
                      id="demo-simple-select-filled"
                      name="class"
                      value={formData.class || []}
                      onChange={handleOnChange}
                      size="small"
                      multiple
                    >
                      <MenuItem value={"all"}>All</MenuItem>

                      {classes &&
                        classes.map((row, index) => (
                          <MenuItem key={row._id} value={row._id}>
                            {row.className}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3}>
                  <FormControl fullWidth required size="small">
                    <InputLabel>Section</InputLabel>
                    <Select
                      label="Section"
                      id="demo-simple-select-filled"
                      name="section"
                      value={formData.section || []}
                      onChange={handleOnChange}
                      multiple
                      size="small"
                    >
                      {formData.class && sections.length && (
                        <MenuItem value="all">All</MenuItem>
                      )}
                      {sections &&
                        sections.map((row, index) => (
                          <MenuItem key={row._id} value={row._id}>
                            {row.class?.className}-{row.sectionName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3}>
                  <FormControl fullWidth required size="small">
                    <InputLabel>Fee Map</InputLabel>
                    <Select
                      label="Fee Map"
                      id="demo-simple-select-filled-feemapid"
                      name="feeMapId"
                      value={formData.feeMapId || []}
                      onChange={handleOnChange}
                      size="small"
                      multiple
                    >
                      <MenuItem value={"all"}>All</MenuItem>

                      {feeMaps.map((feeMap, index) => (
                        <MenuItem key={feeMap._id} value={feeMap._id}>
                          {showInfo(feeMap)} (₹
                          {feeMap.fee})
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={3}
                  sx={{ alignSelf: "center" }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="From Date"
                      inputFormat="DD-MM-YYYY"
                      value={fromDate}
                      onChange={(newValue) => setFromDate(newValue)}
                      renderInput={(params) => (
                        <TextField {...params} size="small" fullWidth />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={3}
                  sx={{ alignSelf: "center" }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="To Date"
                      inputFormat="DD-MM-YYYY"
                      value={toDate}
                      onChange={(newValue) => setToDate(newValue)}
                      renderInput={(params) => (
                        <TextField {...params} size="small" fullWidth />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{
                    display: "flex",
                    justifyContent: {
                      xs: "center",
                      sm: "center",
                      md: "flex-end",
                      lg: "flex-end",
                    },
                  }}
                >
                  <LoadingButton
                    loading={finder}
                    type="submit"
                    size="small"
                    variant="contained"
                    color="primary"
                    sx={{
                      background: "#1b3779",
                      ":hover": { background: "#1b3779" },
                      color: "#fff",
                    }}
                  >
                    find
                  </LoadingButton>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Card>
      </div>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
          <TableHead style={{ background: "rgb(27 55 121)" }}>
            <TableRow>
              <TableCell align="center">
                <span className="class-table-header">Payment</span>
              </TableCell>
              <TableCell align="center">
                <span className="class-table-header">Total</span>
              </TableCell>
              <TableCell align="center">
                <span className="class-table-header">Balance</span>
              </TableCell>
              <TableCell align="center">
                <span className="class-table-header">Cash</span>
              </TableCell>
              <TableCell align="center">
                <span className="class-table-header">Cheque</span>
              </TableCell>
              <TableCell align="center">
                <span className="class-table-header">DD</span>
              </TableCell>
              <TableCell align="center">
                <span className="class-table-header">Chalan</span>
              </TableCell>
              <TableCell align="center">
                <span className="class-table-header">NetBanking</span>
              </TableCell>
              <TableCell align="center">
                <span className="class-table-header">UPI</span>
              </TableCell>
            </TableRow>
          </TableHead>
          {!Object.keys(differentFeeOverView.modes).length ? (
            <TableBody>
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell align="center" colSpan={8}>
                  <Typography variant="h6" sx={{ textAlign: "center" }}>
                    No data found
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell align="center">
                  Amount
                  <CurrencyRupeeIcon size="small" fontSize="10px" />
                </TableCell>
                <TableCell
                  align="center"
                  style={{ textTransform: "capitalize" }}
                >
                  {differentFeeOverView.total}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ textTransform: "capitalize" }}
                >
                  {differentFeeOverView.balance}
                </TableCell>

                <TableCell
                  align="center"
                  style={{ textTransform: "capitalize" }}
                >
                  {differentFeeOverView.modes.Cash}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ textTransform: "capitalize" }}
                >
                  {differentFeeOverView.modes.Cheque}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ textTransform: "capitalize" }}
                >
                  {differentFeeOverView.modes.DD}{" "}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ textTransform: "capitalize" }}
                >
                  {differentFeeOverView.modes.Card}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ textTransform: "capitalize" }}
                >
                  {differentFeeOverView.modes.Netbanking}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ textTransform: "capitalize" }}
                >
                  {differentFeeOverView.modes.Upi}
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={Object.keys(differentFeeOverView.modes).length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          my: 1,
        }}
      />

      <Box
        sx={{
          alignSelf: "flex-end",
          display: "flex",
          justifyContent: "flex-end",
          columnGap: "10px",
          my: 2,
        }}
      >
        <Select
          value={category}
          onChange={handleCategoryChange}
          size="small"
          style={{ marginRight: "10px" }}
          sx={{ width: "160px" }}
        >
          <MenuItem value="receiptNumber">Receipt No</MenuItem>
          <MenuItem value="totalAmountPaid">Amount</MenuItem>
          <MenuItem value="paymentMode">Payment Mode</MenuItem>
          <MenuItem value="name">Name</MenuItem>
          <MenuItem value="admissionNumber">Admission No</MenuItem>
        </Select>
        <TextField
          label="Search"
          value={searchValue}
          onChange={handleSearchChange}
          size="small"
        />
      </Box>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
          <TableHead style={{ background: "rgb(27 55 121)" }}>
            <TableRow>
              <TableCell align="center">
                <span
                  style={{ cursor: "pointer" }}
                  onClick={sortByReceiptNumber}
                  className="class-table-header"
                >
                  Receipt No
                  <span>{sort.receiptNumber === "asc" ? "▲" : "▼"}</span>
                </span>
              </TableCell>
              <TableCell sx={{ minWidth: "150px" }} align="center">
                <span className="class-table-header">Paid Date</span>
              </TableCell>
              <TableCell align="center">
                <span className="class-table-header">Amount</span>
              </TableCell>
              <TableCell align="center">
                <span className="class-table-header">Payment Mode</span>
              </TableCell>
              <TableCell align="center">
                <span className="class-table-header">Name</span>
              </TableCell>
              <TableCell align="center">
                <span
                  style={{ cursor: "pointer" }}
                  onClick={sortByClass}
                  className="class-table-header"
                >
                  Class
                  <span>{sort.class === "asc" ? "▲" : "▼"}</span>
                </span>
              </TableCell>
              <TableCell align="center">
                <span className="class-table-header">Section</span>
              </TableCell>
              <TableCell align="center">
                <span className="class-table-header">Admission No</span>
              </TableCell>
              <TableCell align="center">
                <span className="class-table-header">View</span>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filterFeeOverview
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow
                  key={row._id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    {row.receiptNumber}
                  </TableCell>

                  <TableCell align="center" sx={{ minWidth: "150px" }}>
                    {/* {row.createdAt} */}
                    {dayjs(row.paidAt).format("DD-MM-YYYY")}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    {row.amountPaid}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    {row.paymentMode}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    {row.payeeDetails.name}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    {row.payeeDetails.className}
                  </TableCell>

                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    {row.payeeDetails.sectionName}
                  </TableCell>

                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    {row.payeeDetails.admissionNumber}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    <LoadingButton
                      loading={row._id == downloadingStaffCopy ? true : false}
                      size="small"
                      variant="contained"
                      color="primary"
                      onClick={() => handleGetPrintPdf(row._id)}
                    >
                      STAFF COPY
                    </LoadingButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {!masterFeeOverview.length && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
          >
            No data found
          </Typography>
        )}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={masterFeeOverview.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          my: 1,
        }}
      />

      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <LoadingButton
          loading={download}
          onClick={handleMarksExcelDownload}
          size="small"
          variant="contained"
          color="primary"
          sx={{
            display: masterFeeOverview.length ? "block" : "none",
          }}
        >
          DOWNLOAD
        </LoadingButton>
      </Box>
    </Box>
  );
}

export default FeeOverviewNew;
