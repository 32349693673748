import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  Avatar,
  InputAdornment,
  IconButton,
  TableContainer,
  TablePagination,
} from "@mui/material";
import { objHas } from "../../utils/utils";
import React, { useContext, useEffect, useState } from "react";
import { del, get, post, put } from "../../services/apis";
import { urls as url } from "../../services/urlConstant";
import { Delete, Search } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import { LoadingButton } from "@mui/lab";
import SettingContext from "../../context/SettingsContext";
import appendStringToUrls from "../../utils/appendString";
import SectionsSubHeader from "../../components/SectionSubHeader";
import DeleteModal from "../Layout/Academic/DeleteModal";
import Wrapper from "../Wrapper";
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 600,
//   height: 370,
//   bgcolor: "background.paper",
//   p: 4,
// };
import ShowComponent from "../ShowComponent";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}
const styles = {
  cardsMargin: {
    margin: "15px o",
  },
  cardStyle: {
    margin: "15px 0",
    padding: "10px",
  },
  icon: {
    padding: "10px",
    fontSize: "12px",
    fontWeight: 500,
  },
  card: {
    padding: 15,
    marginBottom: 20,
    width: "100%",
  },
  textfield: {
    width: "100%",
    label: { fontSize: 12 },
  },
  Button: {
    background: "#1b3779",
    color: "#fff",
    ":hover": { background: "#1b3779" },
  },
};

const ContainerBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: "650px",
  borderRadius: "5px",
  height: "auto",
  backgroundColor: "white",
  padding: "20px",
  // border:"solid red",
  [theme.breakpoints.down("md")]: {
    overflow: "auto",
    width: "90%",
  },
  [theme.breakpoints.between(819, 821)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(767, 769)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(911, 913)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(1023, 1025)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.between(1279, 1281)]: {
    overflow: "auto",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const TransportMember = () => {
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [tabValue, setTabValue] = useState(0);
  const [formData, setFormData] = useState([]);
  const [addData, setAddData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(1000);
  const [students, setStudents] = useState([]);
  const [showPagination, setShowPagination] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [academicYears, setAcademicYears] = useState([]);
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [nonStudents, setNonStudents] = useState([]);
  const [studentToAdd, setStudentToAdd] = useState("");
  const [mode, setMode] = useState("edit");
  const [routes, setRoutes] = useState([]);
  const [deleteModal, setDeleteModal] = React.useState(false);

  const [searchFilter, setSearchFilter] = useState([]);
  const [search, setSearch] = useState("");
  const [nonSearchFilter, setNonSearchFilter] = useState([]);
  const [nonSearch, setNonSearch] = useState("");
  const [selectStudent, setSelectStudent] = useState(null);
  let [loader, setLoader] = useState(false);
  const [addLoader, setAddLoader] = useState(false);

  const [nonHostelMembers, setNonHostelMembers] = useState([]);
  const [hostelMembers, setHostelMembers] = useState([]);

  // pagination================
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // ====================================

  const [sort, setSort] = useState({
    name: "asc",
    rollNo: "asc",
    class: "asc",
    section: "asc",
  });

  const sortByTitle = () => {
    if (sort.name === "asc") {
      const sortedList = [
        ...students.sort((a, b) =>
          b.basicInfo.name.localeCompare(a.basicInfo.name)
        ),
      ];
      setStudents(sortedList);
      setSort({ ...sort, name: "des" });
    } else {
      const sortedList = [
        ...students.sort((a, b) =>
          a.basicInfo.name.localeCompare(b.basicInfo.name)
        ),
      ];
      setStudents(sortedList);
      setSort({ ...sort, name: "asc" });
    }
  };

  const sortByRollNo = () => {
    if (sort.rollNo === "asc") {
      const sortedList = [
        ...students.sort(
          (a, b) => b.academicInfo.rollNo - a.academicInfo.rollNo
        ),
      ];
      setStudents(sortedList);
      setSort({ ...sort, rollNo: "des" });
    } else {
      const sortedList = [
        ...students.sort(
          (a, b) => a.academicInfo.rollNo - b.academicInfo.rollNo
        ),
      ];
      setStudents(sortedList);
      setSort({ ...sort, rollNo: "asc" });
    }
  };
  const sortByNonTitle = () => {
    if (sort.name === "asc") {
      const sortedList = [
        ...nonStudents.sort((a, b) =>
          b.basicInfo.name.localeCompare(a.basicInfo.name)
        ),
      ];
      setNonStudents(sortedList);
      setSort({ ...sort, name: "des" });
    } else {
      const sortedList = [
        ...nonStudents.sort((a, b) =>
          a.basicInfo.name.localeCompare(b.basicInfo.name)
        ),
      ];
      setNonStudents(sortedList);
      setSort({ ...sort, name: "asc" });
    }
  };
  const sortByNonRollNo = () => {
    if (sort.rollNo === "asc") {
      const sortedList = [
        ...nonStudents.sort(
          (a, b) => b.academicInfo.rollNo - a.academicInfo.rollNo
        ),
      ];
      setNonStudents(sortedList);
      setSort({ ...sort, rollNo: "des" });
    } else {
      const sortedList = [
        ...nonStudents.sort(
          (a, b) => a.academicInfo.rollNo - b.academicInfo.rollNo
        ),
      ];
      setNonStudents(sortedList);
      setSort({ ...sort, rollNo: "asc" });
    }
  };
  const sortByNonClass = () => {
    if (sort.class === "asc") {
      const sortedList = [
        ...nonStudents.sort(
          (a, b) =>
            b.academicInfo.class.className - a.academicInfo.class.className
        ),
      ];
      setNonStudents(sortedList);
      setSort({ ...sort, class: "des" });
    } else {
      const sortedList = [
        ...nonStudents.sort(
          (a, b) =>
            a.academicInfo.class.className - b.academicInfo.class.className
        ),
      ];
      setNonStudents(sortedList);
      setSort({ ...sort, class: "asc" });
    }
  };
  const sortByNonSection = () => {
    if (sort.section === "asc") {
      const sortedList = [
        ...nonStudents.sort(
          (a, b) =>
            b.academicInfo.section.sectionName -
            a.academicInfo.section.sectionName
        ),
      ];
      setNonStudents(sortedList);
      setSort({ ...sort, section: "des" });
    } else {
      const sortedList = [
        ...nonStudents.sort(
          (a, b) =>
            a.academicInfo.section.sectionName -
            b.academicInfo.section.sectionName
        ),
      ];
      setNonStudents(sortedList);
      setSort({ ...sort, section: "asc" });
    }
  };

  useEffect(() => {
    const getAcademicYears = async () => {
      try {
        const res = await get(urls.administrator.academicYearGetAll, {
          headers: { roleFunction: "transportMember" },
        });
        setFormData((prev) => ({
          ...prev,
          academicYear: res.data.data.length ? res.data.data[0]._id : "",
        }));
        setAcademicYears(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAcademicYears();
  }, [selectedSetting]);

  useEffect(() => {
    setRoutes([]);
    const getRoutes = async () => {
      try {
        const { data, status } = await get(urls.route.getList, {
          headers: { roleFunction: "transportMember" },
        });
        setRoutes(data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getRoutes();
  }, [selectedSetting]);

  useEffect(() => {
    setClasses([]);
    setSections([]);
    const getClasses = async () => {
      try {
        const res = await get(urls.class.getAllClass, {
          headers: { roleFunction: "transportMember" },
        });

        setClasses(res.data.data);
        setFormData((prev) => ({
          ...prev,
          className: res.data.data[0]?._id,
        }));
      } catch (error) {
        console.log(error);
      }
    };
    getClasses();
  }, [selectedSetting]);

  useEffect(() => {
    setSections([]);
    const getClasses = async () => {
      try {
        const resSections = await get(urls.schoolSection.getAllSection, {
          headers: { roleFunction: "transportMember" },
          params: {
            search: {
              class: formData.className,
            },
          },
        });

        setSections(resSections.data.data);
        setFormData((prev) => ({
          ...prev,
          section: resSections.data.data[0]?._id,
        }));
      } catch (error) {
        console.log(error);
      }
    };
    getClasses();
  }, [selectedSetting, formData.className]);

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() !== "") {
      students.length > 0 &&
        setSearchFilter(
          students.filter((ele) =>
            ele.basicInfo.name
              .toLowerCase()
              .includes(value.toLowerCase().trim())
          )
        );
    } else {
      setSearchFilter([]);
    }
  };

  const handleNonSearch = (e) => {
    const { value } = e.target;
    setNonSearch(value.trim());
    if (value.trim() !== "") {
      nonStudents.length > 0 &&
        setNonSearchFilter(
          nonStudents.filter((ele) =>
            ele.basicInfo.name
              .toLowerCase()
              .includes(value.toLowerCase().trim())
          )
        );
    } else {
      setNonSearchFilter([]);
    }
  };
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleStudentSearchSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      const searchOptions = {
        academicYear: formData.academicYear,
        "academicInfo.class": formData.className,
        status: "active",
        "otherInfo.transportMember": "yes",
      };

      if (formData.section !== "all") {
        searchOptions["academicInfo.section"] = formData.section;
      }

      let searchParams = {
        headers: { roleFunction: "transportMember" },
        params: {
          search: searchOptions,
          limit: rowsPerPage,
          page: page === 0 ? 1 : page + 1,
        },
      };

      const searchStudents = await get(
        urls.students.getAllStudent,
        searchParams
      );
      const sortedStudents = searchStudents.data.data.sort((a, b) => {
        const aIsHostelMember = a?.otherInfo?.transportMember === "yes";
        const bIsHostelMember = b?.otherInfo?.transportMember === "yes";
        if (aIsHostelMember && !bIsHostelMember) {
          return -1; // a comes first
        } else if (!aIsHostelMember && bIsHostelMember) {
          return 1; // b comes first
        } else {
          return 0;
        }
      });

      setStudents(sortedStudents);
      if (searchStudents.data.count > rowsPerPage) {
        setShowPagination(true);
      }
      setTotalCount(searchStudents.data.count);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
    setLoader(false);
  };

  const handleNonStudentSearchSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      const searchOptions = {
        academicYear: formData.academicYear,
        "academicInfo.class": formData.class,
        status: "active",
        "otherInfo.transportMember": "no",
      };

      if (formData.section === "all") {
        const searchStudents = await get(urls.students.getAllStudent, {
          headers: { roleFunction: "transportMember" },
          params: {
            search: searchOptions,
            limit: rowsPerPage,
            page: page + 1,
          },
        });

        const filteredStudents = searchStudents.data.data.filter(
          (student) => student?.otherInfo?.transportMember === "no"
        );

        setNonStudents(filteredStudents);
        if (searchStudents.data.count > rowsPerPage) {
          setShowPagination(true);
        }
        setTotalCount(filteredStudents.length);
      } else {
        const searchStudent = await get(urls.students.getAllStudent, {
          headers: { roleFunction: "transportMember" },
          params: {
            search: {
              ...searchOptions,
              "academicInfo.section": formData.section,
            },
            limit: rowsPerPage,
            page: page === 0 ? 1 : page + 1,
          },
        });

        const filteredStudents = searchStudent.data.data.filter(
          (student) => student?.otherInfo?.transportMember === "no"
        );
        setNonStudents(filteredStudents);
        if (searchStudent.data.count > rowsPerPage) {
          setShowPagination(true);
        }
        setTotalCount(filteredStudents.length);
      }
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleOnChange2 = (e) => {
    const { name, value } = e.target;
    setAddData({
      ...addData,
      [name]: value,
    });
  };

  const handleClassChange = async (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    try {
      const section = await get(urls.schoolSection.getAllSection, {
        headers: { roleFunction: "transportMember" },
        params: {
          search: {
            class: e.target.value,
          },
        },
      });
      setFormData((prev) => ({
        ...prev,
        section: "all",
      }));
      setSections(section.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteMember = async (e, id) => {
    e.preventDefault();

    try {
      const { status } = await put(
        `${urls.transportMember.deleteMember}/`,
        id,
        {},
        { headers: { roleFunction: "transportMember" } }
      );
      getstude();
      getNonStu();
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddTransportButtonClick = (id) => {
    setMode("create");
    setStudentToAdd(id);
    const student = students.find((student) => student._id === id);
    setSelectStudent(student ? student.basicInfo.name : null);
    setOpen(true);
  };

  const handleClickOpen = (id, transportInfo) => {
    setMode("edit");
    setStudentToAdd(id);
    const student = students.find((student) => student._id === id);
    setSelectStudent(student ? student.basicInfo.name : null);
    setAddData({
      route: transportInfo.transportRouteTitle._id,
      busStop: transportInfo.stopName._id,
      pickType: transportInfo.pickType,
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // get member and non members list
  const getNonStu = async () => {
    try {
      const searchOptions = {
        academicYear: formData.academicYear,
        "academicInfo.class": formData.className,
        status: "active",
        "otherInfo.transportMember": "no",
      };

      if (formData.section === "all") {
        const searchStudents = await get(urls.students.getAllStudent, {
          headers: { roleFunction: "transportMember" },
          params: {
            search: searchOptions,
            limit: rowsPerPage,
            page: page + 1,
          },
        });

        const filteredStudents = searchStudents.data.data.filter(
          (student) => student?.otherInfo?.transportMember === "no"
        );

        setNonStudents(filteredStudents);
        if (searchStudents.data.count > rowsPerPage) {
          setShowPagination(true);
        }
        setTotalCount(filteredStudents.length);
      } else {
        const searchStudent = await get(urls.students.getAllStudent, {
          headers: { roleFunction: "transportMember" },
          params: {
            search: {
              ...searchOptions,
              "academicInfo.section": formData.section,
            },
            limit: rowsPerPage,
            page: page === 0 ? 1 : page + 1,
          },
        });

        const filteredStudents = searchStudent.data.data.filter(
          (student) => student?.otherInfo?.transportMember === "no"
        );
        setNonStudents(filteredStudents);
        if (searchStudent.data.count > rowsPerPage) {
          setShowPagination(true);
        }
        setTotalCount(filteredStudents.length);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getstude = async () => {
    try {
      const searchOptions = {
        academicYear: formData.academicYear,
        "academicInfo.class": formData.className,
        status: "active",
        "otherInfo.transportMember": "yes",
      };

      if (formData.section !== "all") {
        searchOptions["academicInfo.section"] = formData.section;
      }

      console.log(searchOptions, "searchOptions student");

      let searchParams = {
        headers: { roleFunction: "transportMember" },
        params: {
          search: searchOptions,
          limit: rowsPerPage,
          page: page === 0 ? 1 : page + 1,
        },
      };

      const searchStudents = await get(
        urls.students.getAllStudent,
        searchParams
      );
      const sortedStudents = searchStudents.data.data.sort((a, b) => {
        const aIsHostelMember = a?.otherInfo?.transportMember === "yes";
        const bIsHostelMember = b?.otherInfo?.transportMember === "yes";
        if (aIsHostelMember && !bIsHostelMember) {
          return -1; // a comes first
        } else if (!aIsHostelMember && bIsHostelMember) {
          return 1; // b comes first
        } else {
          return 0;
        }
      });

      console.log(sortedStudents, "sorted students");

      setStudents(sortedStudents);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (formData.className && formData.section) {
      getNonStu();
      getstude();
    }
  }, [formData.className, formData.section]);

  // add and delete transport member
  const handleAddSubmit = async (e) => {
    e.preventDefault();
    setAddLoader(true);
    try {
      const res = await put(
        `${urls.transportMember.editMember}/`,
        studentToAdd,
        {
          transportRouteTitle: addData.route,
          vehicleNumber: addData.vehicle,
          stopName: addData.busStop,
          pickType: addData.pickType,
        },
        { headers: { roleFunction: "transportMember" } }
      );
      if (res.data.success) {
        getstude();
        getNonStu();
        handleClose();
        setAddData({
          transportRouteTitle: "",
          vehicleNumber: "",
          stopName: "",
          pickType: "",
        });
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
    setAddLoader(false);
  };

  const handleDeleteone = async (id) => {
    // console.log(id, "iddddddddddd");
    try {
      const res = await put(
        `${urls.transportMember.deleteMember}`,
        id,
        {},
        { headers: { roleFunction: "transportMember" } }
      );
      const filteredEvent = students.filter((item) => item._id !== id);
      setStudents(filteredEvent);
      getstude();
      getNonStu();
    } catch (error) {
      console.log(error);
    }
  };
  //

  return (
    <Wrapper>
      <div style={styles.cardsMargin}>
        <SectionsSubHeader title="Transport Member" />
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            margin: "15px 0",
          }}
        >
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="basic tabs example"
          >
            <Tab label="Member List" {...a11yProps(0)} />
            <Tab label="Add Members" {...a11yProps(1)} />
          </Tabs>
        </Box>

        <TabPanel value={tabValue} index={0}>
          <Card style={styles.cardStyle}>
            <Box sx={{ flexGrow: 1 }}>
              <form onSubmit={handleStudentSearchSubmit}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6} lg={4}>
                    <FormControl fullWidth required>
                      <InputLabel>Academic Year</InputLabel>
                      <Select
                        label="Academic Year"
                        id="demo-simple-select-filled"
                        name="academicYear"
                        value={formData.academicYear || ""}
                        onChange={handleOnChange}
                        size="small"
                      >
                        {academicYears &&
                          academicYears.map((row, index) => (
                            <MenuItem key={row._id} value={row._id}>
                              {row.academicYearFrom} - {row.academicYearTo}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <FormControl size="small" fullWidth required>
                      <InputLabel>Class</InputLabel>
                      <Select
                        label="Class"
                        id="demo-simple-select-filled"
                        name="className"
                        value={formData.className || ""}
                        onChange={handleClassChange}
                        size="small"
                      >
                        {classes &&
                          classes.map((row, index) => (
                            <MenuItem key={row._id} value={row._id}>
                              {row.className}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <FormControl size="small" fullWidth required>
                      <InputLabel>Section</InputLabel>
                      <Select
                        label="Section"
                        id="demo-simple-select-filled"
                        name="section"
                        value={formData.section || ""}
                        onChange={handleOnChange}
                        size="small"
                      >
                        {formData.className && (
                          <MenuItem value="all">All</MenuItem>
                        )}
                        {sections.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.sectionName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={6}
                    lg={1}
                    style={{ alignSelf: "flex-end" }}
                  >
                    <LoadingButton
                      loading={loader}
                      onClick={handleStudentSearchSubmit}
                      size="small"
                      variant="contained"
                      sx={{
                        background: "rgb(27, 55, 121)",
                        ":hover": { background: "rgb(27, 55, 121)" },
                      }}
                    >
                      SEARCH
                    </LoadingButton>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Card>

          <Grid
            container
            spacing={2}
            justifyContent="flex-end"
            sx={{ marginTop: "15px" }}
          >
            <Grid xs="auto">
              <TextField
                size="small"
                value={search}
                placeholder="Enter Name"
                onChange={handleSearch}
                label="Search Name"
                variant="outlined"
                sx={{ my: 2 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" type="submit">
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
                <TableRow sx={{ color: "white" }}>
                  <TableCell sx={{ color: "white" }} align="center">
                    #SL
                  </TableCell>
                  <TableCell sx={{ color: "white" }} align="center">
                    <span onClick={sortByTitle} className="class-table-header">
                      Name
                      <span style={{ cursor: "pointer" }}>
                        {sort.name === "asc" ? "▲" : "▼"}
                      </span>
                    </span>
                  </TableCell>
                  <TableCell sx={{ color: "white" }} align="center">
                    <span onClick={sortByRollNo} className="class-table-header">
                      Roll No
                      <span style={{ cursor: "pointer" }}>
                        {sort.rollNo === "asc" ? "▲" : "▼"}
                      </span>
                    </span>
                  </TableCell>
                  <TableCell sx={{ color: "white" }} align="center">
                    Stop Name
                  </TableCell>
                  <TableCell sx={{ color: "white" }} align="center">
                    Pick Type
                  </TableCell>
                  <TableCell sx={{ color: "white" }} align="center">
                    Route Start
                  </TableCell>
                  <TableCell sx={{ color: "white" }} align="center">
                    Route End
                  </TableCell>
                  <TableCell sx={{ color: "white" }} align="center">
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {search
                  ? searchFilter
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((data, index) => (
                        <TableRow>
                          <TableCell align="center">{index + 1}</TableCell>
                          <TableCell align="center">
                            {data.basicInfo.name}
                          </TableCell>

                          <TableCell align="center">
                            {data.academicInfo.rollNo}
                          </TableCell>
                          <TableCell align="center">
                            {data.transportInfo && data.transportInfo.stopName
                              ? data.transportInfo.stopName.stopName
                              : "NA"}
                          </TableCell>
                          <TableCell align="center">
                            {data?.otherInfo?.transportMember === "yes" ? (
                              <div className="edit-delete">
                                <ShowComponent
                                  roleFunction="transportMember"
                                  action="update"
                                >
                                  <Tooltip title="Update">
                                    <IconButton
                                      size="small"
                                      style={{ color: "#1b3779" }}
                                      onClick={() =>
                                        handleClickOpen(
                                          data._id,
                                          data.transportInfo
                                        )
                                      }
                                    >
                                      <EditIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                </ShowComponent>
                                <ShowComponent
                                  roleFunction="transportMember"
                                  action="delete"
                                >
                                  <Tooltip title="Delete">
                                    <IconButton
                                      size="small"
                                      color="error"
                                      onClick={() => setDeleteModal(data._id)}
                                    >
                                      <Delete fontSize="small" color="error" />
                                    </IconButton>
                                  </Tooltip>
                                </ShowComponent>
                                <DeleteModal
                                  deleteModal={deleteModal}
                                  handleDelete={handleDeleteone}
                                  id={data._id}
                                  setDeleteModal={setDeleteModal}
                                />
                              </div>
                            ) : (
                              <ShowComponent
                                roleFunction="transportMember"
                                action="update"
                              >
                                {" "}
                                <Button
                                  onClick={() =>
                                    handleAddTransportButtonClick(data._id)
                                  }
                                >
                                  Add Member
                                </Button>
                              </ShowComponent>
                            )}
                          </TableCell>
                        </TableRow>
                      ))
                  : students
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((data, index) => (
                        <TableRow>
                          <TableCell align="center">
                            {page * rowsPerPage + index + 1}
                          </TableCell>
                          <TableCell align="center">
                            {data.basicInfo.name}
                          </TableCell>

                          <TableCell align="center">
                            {data.academicInfo.rollNo}
                          </TableCell>
                          <TableCell align="center">
                            {data.transportInfo && data.transportInfo.stopName
                              ? data.transportInfo.stopName.stopName
                              : "NA"}
                          </TableCell>
                          <TableCell align="center">
                            {data.transportInfo && data.transportInfo.pickType
                              ? data.transportInfo.pickType
                              : "NA"}
                          </TableCell>

                          <TableCell align="center">
                            {data.transportInfo &&
                            data.transportInfo.transportRouteTitle?.routeStart
                              ? data.transportInfo.transportRouteTitle
                                  .routeStart
                              : "NA"}
                          </TableCell>
                          <TableCell align="center">
                            {data.transportInfo &&
                            data.transportInfo.transportRouteTitle?.routeEnd
                              ? data.transportInfo.transportRouteTitle.routeEnd
                              : "NA"}
                          </TableCell>
                          <TableCell align="center">
                            {data?.otherInfo?.transportMember === "yes" ? (
                              <div className="edit-delete">
                                <ShowComponent
                                  roleFunction="transportMember"
                                  action="update"
                                >
                                  <Tooltip title="Update">
                                    <IconButton
                                      size="small"
                                      style={{ color: "#1b3779" }}
                                      onClick={() =>
                                        handleClickOpen(
                                          data._id,
                                          data.transportInfo
                                        )
                                      }
                                    >
                                      <EditIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                </ShowComponent>
                                <ShowComponent
                                  roleFunction="transportMember"
                                  action="delete"
                                >
                                  <Tooltip title="Delete">
                                    <IconButton
                                      color="error"
                                      size="small"
                                      onClick={() => setDeleteModal(data._id)}
                                    >
                                      <Delete fontSize="small" color="error" />
                                    </IconButton>
                                  </Tooltip>
                                </ShowComponent>
                                <DeleteModal
                                  deleteModal={deleteModal}
                                  handleDelete={handleDeleteone}
                                  id={data._id}
                                  setDeleteModal={setDeleteModal}
                                />
                              </div>
                            ) : (
                              <ShowComponent
                                roleFunction="transportMember"
                                action="update"
                              >
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() =>
                                    handleAddTransportButtonClick(data._id)
                                  }
                                  startIcon={<AddIcon fontSize="small" />}
                                >
                                  Member
                                </Button>
                              </ShowComponent>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
              </TableBody>
            </Table>
            {!students.length && !search.trim() && (
              <Typography
                variant="h6"
                sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
              >
                No data found
              </Typography>
            )}
            {search.trim() && !searchFilter.length && (
              <Typography
                variant="h6"
                sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
              >
                No data found
              </Typography>
            )}
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={search ? searchFilter.length : students.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              display: "flex",
              justifyContent: "flex-start,",
              alignItems: "center",
              my: 1,
            }}
          />
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          <Card style={styles.cardStyle}>
            <Box sx={{ flexGrow: 1 }}>
              <form onSubmit={(e) => handleNonStudentSearchSubmit(e)}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6} lg={4}>
                    <FormControl fullWidth required>
                      <InputLabel>Academic Year</InputLabel>
                      <Select
                        label="Academic Year"
                        id="demo-simple-select-filled"
                        name="academicYear"
                        value={formData.academicYear || ""}
                        onChange={handleOnChange}
                        size="small"
                      >
                        {academicYears &&
                          academicYears.map((row) => (
                            <MenuItem key={row._id} value={row._id}>
                              {row.academicYearFrom} - {row.academicYearTo}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <FormControl fullWidth required size="small">
                      <InputLabel>Class</InputLabel>
                      <Select
                        label="Class"
                        id="demo-simple-select-filled"
                        name="className"
                        value={formData.className || ""}
                        onChange={handleClassChange}
                        size="small"
                      >
                        {classes &&
                          classes.map((row) => (
                            <MenuItem key={row._id} value={row._id}>
                              {row.className}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <FormControl size="small" fullWidth required>
                      <InputLabel>Section</InputLabel>
                      <Select
                        label="Section"
                        id="demo-simple-select-filled"
                        name="section"
                        value={formData.section || ""}
                        onChange={handleOnChange}
                        size="small"
                      >
                        {formData.className && (
                          <MenuItem value="all">All</MenuItem>
                        )}
                        {sections.map((row) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.sectionName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={6}
                    lg={1}
                    style={{ alignSelf: "flex-end" }}
                  >
                    <LoadingButton
                      loading={loader}
                      onClick={handleNonStudentSearchSubmit}
                      size="small"
                      variant="contained"
                      sx={{
                        background: "rgb(27, 55, 121)",
                        ":hover": { background: "rgb(27, 55, 121)" },
                      }}
                    >
                      SEARCH
                    </LoadingButton>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Card>
          <div style={{ margin: "15px 0" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                margin: "15px",
              }}
            >
              <Grid container spacing={2} justifyContent="flex-end">
                <Grid xs="auto">
                  <TextField
                    fullWidth
                    size="small"
                    // style={styles.textfield}
                    value={nonSearch}
                    onChange={handleNonSearch}
                    placeholder="Enter student name..."
                    label="Search"
                    variant="outlined"
                    sx={{ mt: 1.2 }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton edge="end" type="submit">
                            <Search />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </div>
            <Paper sx={{ overflow: { xs: "scroll", sm: "visible" } }}>
              <Table size="small">
                <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
                  <TableRow sx={{ color: "white" }}>
                    <TableCell sx={{ color: "white" }} align="center">
                      #SL
                    </TableCell>
                    <TableCell sx={{ color: "white" }} align="center">
                      Photo
                    </TableCell>
                    <TableCell sx={{ color: "white" }} align="center">
                      <span
                        onClick={sortByNonTitle}
                        className="class-table-header"
                      >
                        Name
                        <span style={{ cursor: "pointer" }}>
                          {sort.name === "asc" ? "▲" : "▼"}
                        </span>
                      </span>
                    </TableCell>
                    <TableCell sx={{ color: "white" }} align="center">
                      <span
                        onClick={sortByNonClass}
                        className="class-table-header"
                      >
                        Class
                        <span style={{ cursor: "pointer" }}>
                          {sort.class === "asc" ? "▲" : "▼"}
                        </span>
                      </span>
                    </TableCell>
                    <TableCell sx={{ color: "white" }} align="center">
                      <span
                        onClick={sortByNonSection}
                        className="class-table-header"
                      >
                        Section
                        <span style={{ cursor: "pointer" }}>
                          {sort.section === "asc" ? "▲" : "▼"}
                        </span>
                      </span>
                    </TableCell>
                    <TableCell sx={{ color: "white" }} align="center">
                      <span
                        onClick={sortByNonRollNo}
                        className="class-table-header"
                      >
                        Roll No
                        <span style={{ cursor: "pointer" }}>
                          {sort.rollNo === "asc" ? "▲" : "▼"}
                        </span>
                      </span>
                    </TableCell>

                    <TableCell sx={{ color: "white" }} align="center">
                      Select
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {nonSearch
                    ? nonSearchFilter.map((data, index) => (
                        <TableRow>
                          <TableCell align="center">{index + 1}</TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              alignSelf: "center",
                            }}
                          >
                            <Avatar
                              src={objHas(data, "studentPhoto.link", "")}
                            />
                          </TableCell>

                          <TableCell align="center">
                            {data.basicInfo.name}
                          </TableCell>

                          <TableCell align="center">
                            {data.academicInfo.class.className}
                          </TableCell>
                          <TableCell align="center">
                            {data.academicInfo.section.sectionName}
                          </TableCell>

                          <TableCell align="center">
                            {data.academicInfo.rollNo}
                          </TableCell>
                          <TableCell align="center">
                            <ShowComponent
                              roleFunction="transportMember"
                              action="update"
                            >
                              <Button
                                onClick={() =>
                                  handleAddTransportButtonClick(data._id)
                                }
                              >
                                Add Member
                              </Button>
                            </ShowComponent>
                          </TableCell>
                        </TableRow>
                      ))
                    : nonStudents.map((data, index) => (
                        <TableRow>
                          <TableCell align="center">{index + 1}</TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              alignSelf: "center",
                            }}
                          >
                            <Avatar
                              src={objHas(data, "studentPhoto.link", "")}
                            />
                          </TableCell>

                          <TableCell align="center">
                            {data.basicInfo.name}
                          </TableCell>

                          <TableCell align="center">
                            {data.academicInfo.class.className}
                          </TableCell>
                          <TableCell align="center">
                            {data.academicInfo.section.sectionName}
                          </TableCell>

                          <TableCell align="center">
                            {data.academicInfo.rollNo}
                          </TableCell>
                          <TableCell align="center">
                            <ShowComponent
                              roleFunction="transportMember"
                              action="update"
                            >
                              <Button
                                size="small"
                                variant="contained"
                                onClick={() =>
                                  handleAddTransportButtonClick(data._id)
                                }
                                startIcon={<AddIcon fontSize="small" />}
                              >
                                Member
                              </Button>
                            </ShowComponent>
                          </TableCell>
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
              {!nonStudents.length && (
                <Typography
                  variant="h6"
                  sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
                >
                  No data found
                </Typography>
              )}
              {nonSearch.trim() && !nonSearchFilter.length && (
                <Typography
                  variant="h6"
                  sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
                >
                  No data found
                </Typography>
              )}
            </Paper>
          </div>
        </TabPanel>

        {/* ==== Add/Update transport members ============*/}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <form
            onSubmit={(e) => {
              handleAddSubmit(e);
            }}
          >
            <ContainerBox>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                fontWeight="bold"
                textAlign="center"
                marginBottom="20px"
              >
                {mode === "edit" ? "Update" : "Add Member"}
              </Typography>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                textAlign="start"
                marginBottom="5px"
              >
                {selectStudent}
              </Typography>
              <div>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6} lg={6}>
                    <FormControl fullWidth size="small" required>
                      <InputLabel>Select Route</InputLabel>
                      <Select
                        label="Select Route"
                        id="demo-simple-select-filled"
                        name="route"
                        fullWidth
                        value={addData.route || ""}
                        variant="outlined"
                        onChange={handleOnChange2}
                        size="small"
                      >
                        {routes.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.transportRouteTitle}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* <Grid item xs={12} md={6} lg={6}>
                    <FormControl fullWidth size="small" required>
                      <InputLabel>Select Vehicle</InputLabel>
                      <Select
                        label="Select Vehicle"
                        id="demo-simple-select-filled"
                        name="vehicle"
                        fullWidth
                        value={addData.vehicle || ""}
                        onChange={handleOnChange2}
                        variant="outlined"
                        size="small"
                      >
                        {routes.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row?.vehicleNumber?.vehicleNumber}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid> */}

                  <Grid item xs={12} md={6} lg={6}>
                    <FormControl fullWidth size="small" required>
                      <InputLabel>Select Bus Stop</InputLabel>
                      <Select
                        label="Select Bus Stop"
                        id="demo-simple-select-filled"
                        name="busStop"
                        fullWidth
                        value={addData.busStop || ""}
                        onChange={handleOnChange2}
                        size="small"
                      >
                        {routes
                          .filter((r) => r._id == addData.route)
                          .map((row, index) =>
                            row.routeDetails?.map((route, routeIndex) => (
                              <MenuItem
                                key={route._id}
                                value={route._id}
                                variant="outlined"
                                sx={{ fontSize: 12, fontWeight: 500 }}
                              >
                                {route.stopName}
                              </MenuItem>
                            ))
                          )}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={12} lg={12}>
                    <FormControl fullWidth size="small" required>
                      <InputLabel>Select Pick Type</InputLabel>
                      <Select
                        label="Select Pick Type"
                        id="demo-simple-select-filled"
                        name="pickType"
                        value={addData.pickType || ""}
                        variant="outlined"
                        onChange={handleOnChange2}
                        size="small"
                        fullWidth
                      >
                        <MenuItem value={"Both"}>Both</MenuItem>
                        <MenuItem value={"Pick"}>Pick</MenuItem>
                        <MenuItem value={"Drop"}>Drop</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    sx={{
                      display: "flex",
                      gap: "1%",
                      alignItems: "center",
                      justifyContent: {
                        md: "right",
                        sm: "right",
                        xs: "center",
                      },
                      marginTop: "10px",
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={handleClose}
                      size="small"
                      color="error"
                      type="button"
                    >
                      Cancel
                    </Button>

                    <LoadingButton
                      loading={addLoader}
                      variant="contained"
                      size="small"
                      type="submit"
                      sx={{
                        background: "rgb(27, 55, 121)",
                        ":hover": { background: "rgb(27, 55, 121)" },
                      }}
                    >
                      Add
                    </LoadingButton>
                  </Grid>
                </Grid>
              </div>
            </ContainerBox>
          </form>
        </Modal>
        {/* ====================================== */}
      </div>
    </Wrapper>
  );
};

export default TransportMember;
